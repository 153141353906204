import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";
import { clearReduxStore } from "../store/globalActions";

export const changeTeacherPermissions = createAsyncThunk(
  "teacherPermissions/changeTeacherPermissions",
  async (object) => {
    try {
      const response = await fetch(`${baseUrl}/change_text_field`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addNewStudent = createAsyncThunk(
  "teacherPermissions/addNewStudent",
  async (object) => {
    try {
      const url = `${baseUrl}/add_user`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchTeacherPermissions = createAsyncThunk(
  "teacherPermissions/fetchTeacherPermissions",
  async (obj) => {
    const response = await fetch(
      `${baseUrl}/get_permissions/${obj.search_by_adult_or_student}/${obj.id}`
    );
    console.log("Permissions found.");
    const permissions = await response.json();
    return permissions;
  }
);

const initialState = {
  teacherPermissions: [],
  status: "idle",
  error: null,
};

const teacherPermissionsSlice = createSlice({
  name: "teacherPermissions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeacherPermissions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTeacherPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teacherPermissions = state.teacherPermissions.concat(
          action.payload
        );
      })
      .addCase(clearReduxStore, () => initialState)
      .addCase(fetchTeacherPermissions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewStudent.fulfilled, (state, action) => {
        state.teacherPermissions.push(action.payload);
      })
      .addCase(changeTeacherPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        const data = state.teacherPermissions;
        let index = state.teacherPermissions.findIndex(
          (item) => item.id === action.payload.id
        );
        const permission = data[index];

        // permission[attributeName] = action.payload.content;
        const updatedPermission = {
          ...permission,
          ...action.payload,
        };
        return {
          ...state,
          teacherPermissions: [
            // create a new data array
            ...data.slice(0, index), // the posts before the updated comment
            updatedPermission, // the updated post
            ...data.slice(index + 1), // the posts after the updated comment
          ],
        };
      });
  },
});

export default teacherPermissionsSlice.reducer;

export const selectTeacherPermissions = (state) => {
  return state.teacherPermissions.teacherPermissions;
  // .slice()
  // .sort(function (a, b) {
  //   var nameA = a.last_name.toLowerCase(),
  //     nameB = b.last_name.toLowerCase();
  //   if (nameA < nameB) return -1;
  //   if (nameA > nameB) return 1;
  //   return 0;
  // });
};

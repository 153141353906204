import React, { useContext, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import CustomMenu from "../../Dropdown/CustomMenu";
import CustomToggle from "../../Dropdown/CustomToggle";
import ProfilePicIcon from "./ProfilePicIcon";
import { MDBTooltip } from "mdb-react-ui-kit";
import "../NavBar.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Trophy,
  BoxArrowRight,
  PersonAdd,
  Person,
  Airplane,
} from "react-bootstrap-icons";
import AddStudentModal from "../../AddStudent/AddStudentModal";
import { useSelector } from "react-redux";
import { selectUser } from "../../../reducers/sessionSlice";

import TeacherAddGoalModal from "../../TeacherAddGoal/TeacherAddGoalModal";
import StudentQuickAddModal from "../../TeacherWall/StudentQuickAddModal";
import { AuthContext } from "../../../contexts/AuthContext";

export const AccountButton = () => {
  const { t } = useTranslation();
  const [addStudentModal, setAddStudentModal] = useState(false);
  const [teacherGoalModal, setTeacherGoalModal] = useState(false);
  const [showQuickAdd, setShowQuickAdd] = useState(false);
  const authCtx = useContext(AuthContext);
  const user = useSelector(selectUser);

  return (
    <>
      <Dropdown
        id={"accountButton"}
        align={{ sm: "start" | "end" }}
        drop={"up"}
        className="account-toggle">
        <MDBTooltip tag="div" placement="bottom" title={t("navbar.Account")}>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <ProfilePicIcon
              width="30px"
              height="30px"
              src={user?.profile_pic_src}
              style={{ cursor: "pointer" }}
            />
          </Dropdown.Toggle>
        </MDBTooltip>

        <Dropdown.Menu as={CustomMenu} labeledBy="Account">
          <Dropdown.ItemText className="cursor-none fw-bold">
            {user?.first_name} {user?.last_name}
          </Dropdown.ItemText>

          <Dropdown.Divider />
          <Dropdown.Item as={Link} to={"/profile"} eventKey="1">
            <Person className={"me-2 fa-fw"} style={{ color: "#38344c" }} />
            {t("navbar.Profile")}
          </Dropdown.Item>

          {/* {user?.role === "Student" && (
            <Dropdown.Item as={Link} to={"/risk"} eventKey="1">
              <EmojiSunglasses
                className={"me-2 fa-fw"}
                style={{ color: "#38344c" }}
              />
              {t("navbar.Risk Taking")}
            </Dropdown.Item>
          )} */}

          {user?.role === "Teacher" && (
            <>
              <Dropdown.Item
                as="button"
                onClick={() => setAddStudentModal(true)}>
                <PersonAdd
                  className={"me-2 fa-fw addStudent"}
                  style={{ color: "#38344c" }}
                />
                {t("navbar.Add Students")}
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setShowQuickAdd(true)}>
                <Airplane
                  className={"me-2 fa-fw"}
                  style={{ color: "#38344c" }}
                />
                {t("quickAdd.Quick Add")}
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => setTeacherGoalModal(true)}>
                <Trophy className={"me-2 fa-fw"} style={{ color: "#38344c" }} />
                {t("navbar.Add Goals")}
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Item as="button" onClick={() => authCtx.logout()}>
            <BoxArrowRight
              className={"me-2 fa-fw"}
              style={{ color: "#38344c" }}
            />

            {t("navbar.Logout")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <AddStudentModal
        show={addStudentModal}
        handleClose={() => setAddStudentModal(false)}
        district_levels={user?.district_levels}
        school={user?.school}
        school_id={user?.school_id}
        primary_teacher_id={user?.primary_teacher_id}
        district_goals={user?.district_goals}
        district_id={user?.district_id}
      />
      {teacherGoalModal && (
        <TeacherAddGoalModal
          show={teacherGoalModal}
          handleClose={() => setTeacherGoalModal(false)}
        />
      )}
      {showQuickAdd && (
        <StudentQuickAddModal
          show={showQuickAdd}
          handleClose={() => setShowQuickAdd(false)}
        />
      )}
    </>
  );
};

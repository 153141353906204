import { createContext, useState } from "react";
import io from "socket.io-client";
import { socketUrl } from "../utils/baseUrl";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { clearReduxStore } from "../store/globalActions";

export const AuthContext = createContext({
  token: "",
  isAuthenticated: false,
  authenticate: () => {},
  logout: () => {},
  connectSocket: () => {},
  disconnectSocket: () => {}, // Add the disconnect function to context
  socket: null,
});

function AuthProvider({ children }) {
  const [authToken, setAuthToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [socket, setSocket] = useState(null); // State to hold the socket instance
  const dispatch = useDispatch();

  const microsoftLogoutUser = async (instance) => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  function authenticate(token) {
    if (token) {
      setAuthToken(token);
      Cookies.set("token", token.mail, { expires: 7 });
    } else {
      setAuthToken(null);
      Cookies.remove("token"); // Clear token if null
    }

    // Set authentication status
    const isAuthenticated = !!token;
    setIsAuthenticated(isAuthenticated);

    console.log("isAuthenticated:", isAuthenticated);
  }

  function logout(instance) {
    console.log("instance", instance);
    Cookies.remove("token");
    setAuthToken("");
    setIsAuthenticated(false);
    dispatch(clearReduxStore());
    if (!!instance) {
      microsoftLogoutUser(instance);
    }
  }

  // Function to disconnect from socket
  function disconnectSocket() {
    if (socket) {
      console.log("Disconnecting from the current socket...");
      socket.disconnect(); // Gracefully disconnect the socket
      setSocket(null); // Reset socket state
    }
  }

  function connectSocket(userId) {
    disconnectSocket(); // Ensure any previous connection is closed before a new one
    console.log("Attempting to connect to socket with userID", userId);
    const newSocket = io(socketUrl, {
      reconnection: true,
      transports: ["websocket"],
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });
    newSocket.on("connect", () => {
      console.log("Connected to WebSocket");
      newSocket.emit("join_room", { room: userId });
      newSocket.on("room_joined", (data) => {
        console.log(data.message);
      });
    });
    setSocket(newSocket);
  }

  const value = {
    token: authToken,
    isAuthenticated: isAuthenticated,
    authenticate: authenticate,
    logout: logout,
    connectSocket: connectSocket,
    disconnectSocket: disconnectSocket, // Pass disconnect function in context
    socket: socket,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

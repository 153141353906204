import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { COLORS, rankingColors } from "../../utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroupings } from "../../reducers/groupingsSlice";
import { selectUser } from "../../reducers/sessionSlice";
import ProfilePicIcon from "../Navbar/buttons/ProfilePicIcon";
import baseUrl from "../../utils/baseUrl";
import { MainContext } from "../../contexts/MainContext";
import ProfileInfoModal from "../TeacherWall/ProfileInfoModal";

const profilePicHeight = 30;

export default function Leaderboard({ timeFrame }) {
  const user = useSelector(selectUser);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeGroup, setActiveGroup] = useState("");
  const [activeCategory, setActiveCategory] = useState("school");
  const [activeTimeframe, setActivetimeframe] = useState("month");
  const [data, setData] = useState([]);
  const winnersArray = Object.entries(data);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const groupingsStatus = useSelector((state) => state.groupings.status);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const mainCtx = useContext(MainContext);
  const modalHandler = (student_id) => {
    setShowProfileModal(true);
    mainCtx.setActiveStudent(student_id);
  };

  useEffect(() => {
    if (groupingsStatus === "idle") {
      dispatch(fetchGroupings(user.id));
    }
  }, [groupingsStatus, dispatch, user.id]);

  useEffect(() => {
    const getRanksData = async () => {
      setErrorMessage("");
      setIsLoading(true);
      const obj = {
        category: activeCategory,
        timeframeInDays: activeTimeframe === "month" ? 31 : 365,
        userId: null,
        primaryTeacherId: user.id,
        schoolId: user.school_id,
        groupingId: activeGroup,
      };
      try {
        const response = await fetch(`${baseUrl}/get_risk_ranks`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(obj),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (!!data.error) {
          setErrorMessage(data.error);
        } else {
          setData(data);
        }
      } catch (error) {
        console.error("Error:", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    getRanksData();
  }, [activeCategory, activeTimeframe, setIsLoading]);

  return (
    <>
      <div className="card shadow h-100">
        <div className="card-header">
          {t(`risks.${activeTimeframe}`).toUpperCase()}
        </div>
        <div className="card-body">
          {winnersArray.length === 0 ? (
            <div className="text-center">{t("noPointsMessage")}</div>
          ) : (
            winnersArray.map(([key, item], index) => {
              const numericKey = parseInt(key, 10); // Convert key to a number
              return (
                <div
                  key={key}
                  onClick={() => modalHandler(item.user_id)}
                  className={`d-flex align-items-center justify-content-center py-2 hover-effect p-1 ${
                    index !== winnersArray.length - 1 ? "border-bottom" : ""
                  }`}
                  style={{ borderBottomColor: "lightgray" }}>
                  <div className="d-flex align-items-center me-3">
                    {numericKey < 3 ? (
                      <FontAwesomeIcon
                        icon={faMedal}
                        size={profilePicHeight - 10}
                        color={rankingColors[numericKey]}
                      />
                    ) : (
                      <span className="fw-bold">{numericKey}th</span>
                    )}
                  </div>
                  <ProfilePicIcon
                    height={profilePicHeight}
                    width={profilePicHeight}
                    src={item.profile_pic_src}
                    style={{ cursor: "pointer" }}
                  />

                  <div
                    className="d-flex align-items-center ms-3 flex-grow-1 p-2 rounded"
                    style={{
                      backgroundColor: "white",
                      minWidth: "66%",
                    }}>
                    <span className="me-auto fw-bold">
                      {`${item.first_name} ${item.last_name}`}
                    </span>
                    <span style={{ color: COLORS[0] }}>
                      {`${item.points} ${t("risks.pointsAbbreviation")}`}
                    </span>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      {showProfileModal && (
        <ProfileInfoModal
          show={showProfileModal}
          onHide={() => setShowProfileModal(false)}
          activePage="risks"
        />
      )}
    </>
  );
}
